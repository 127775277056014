import logo from './logo.svg';
import './App.css';
import React, { useState } from "react";
import ReactDOM from "react-dom";
import Home from './components/Home';
import Login from './components/login';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import "./css/home.css";
import "./css/login.css";

function App() {
  
  return (
  
      <Router>
      <Routes>
            <Route path= "/" element={ <Navigate to="/login"/> } />
             <Route path='/login' element={<Login/>} />
             <Route path='/home' element={<Home/>} />
             <Route path='/lo' element={<Login/>}/>
      </Routes>
    </Router>
  );
}

export default App;
