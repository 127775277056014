import React from "react";
import { useState, useEffect  } from "react";

import { useNavigate } from "react-router-dom";

import App from "../App";
import config from "../config/config"

function Login() {

    const navigate = useNavigate();

    const [errorMessages, setErrorMessages] = useState({});
    //const [isSubmitted, setIsSubmitted] = useState(false);

    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false));

    
    const errors = {

        email: "Invalid Email/Password",
        pass: "Invalid Password"
    };
 
    useEffect(() => {
        //Runs on every render

        
        try {

            console.log("Came here");

            let status = localStorage.getItem("authenticated");
            console.dir(status);
            if("" + status == "true") {
                console.log("Matched");
                navigate("/home");
            }

        } catch(e) {
            console.error(e);
        }

    });
    
    const handleSubmit = async (event) => {

        try {

            //Prevent page reload
            event.preventDefault();
        
            var { email_id, password_id} = document.forms[0];

            if(email == '') {

                alert("Please enter Email.");
                email_id.focus();
                return;
            }

            if(password == '') {

                alert("Please enter Password.");
                password_id.focus();
                return;
            }


            let jsonObject = {
                username: email,
                password: password,
            }

            let url = config.URL + "/" + "authenticate";
            let response = await fetch(url, {

                method: 'POST',
                body: JSON.stringify(jsonObject),
                mode: 'cors',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }

            })

            const data = await response.json();
            console.dir(data);
            
            if (data.success) {

                setauthenticated(true)
                localStorage.setItem("authenticated", true);
                localStorage.setItem("LOGIN_USER", email);
                localStorage.setItem("UserName", data.name);

                navigate("/home");

            } else {
                setErrorMessages({ name: "error_email", message: errors.email });
            }

        }catch(e) {

            console.log("Error in handleSubmit :: "+e);
        }
        
    };

    const renderErrorMessage = (name) =>

        name === errorMessages.name && (

        <div className="error">{errorMessages.message}</div>
    );

    try {

        console.log("Came here");

        let status = localStorage.getItem("authenticated");
        console.dir(status);
        if("" + status == "true") {
            
            console.log("Matched");
            
            return (
                <div>
                </div>
            );

        }

    } catch(e) {
        console.error(e);
    }
    
        return (

            <div class="login-div">

                <div class="containers" id="container">
        
                    <div class="form-container sign-in-container">

                        <form onSubmit={handleSubmit} class="login-form">
                            <h1 style={{color: '#3088E5', fontSize: 40}}>Sign In</h1>
                            
                            {renderErrorMessage("error_email")}
                            {renderErrorMessage("error_password")}

                            <input type="email" placeholder="Email" class="login-input" name="email_id" onChange={(e) => setemail(e.target.value)} value={email}/>
                            <input type="password" placeholder="Password" class="login-input" name="password_id" onChange={(e) => setpassword(e.target.value)}/> 
                            <br/>
                            <input type="submit" class="login-button" value="Sign In"/>

			    <br/>
			            </form>
                    </div>
                    
                </div>


            </div>
            
        )
    };


export default Login;
