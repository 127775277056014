import React from "react";
import { useState, useEffect } from "react";
import config from "../config/config"
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Modal from 'react-modal';

import { useNavigate, Link } from "react-router-dom";
import Handler from '../handlers/handler'
import $ from 'jquery';

function ShowUsers() {

    const navigate = useNavigate();

    const [errorMessages, setErrorMessages] = useState({});
    const [list, setList] = useState([]);
    const [full_list, setFullList] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [name, setName] = useState(Handler.name);

    let file = null;

    useEffect(() => {
        //Runs on every render
        try {

            console.log("ShowAccounts Came here");
            getAllUsers();

        } catch (e) {
            console.error(e);
        }

    }, []);

    async function getAllUsers() {
        try {

            let url = config.URL + "/get_users?name=" + name;
            let response = await fetch(url, {

                method: 'GET',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }

            });

            let json_response = await response.json();
            if(json_response.error) {
                return;
            }

            console.dir(json_response);

            let new_list = [];

            full_list.splice(0, full_list.length);

            for(let i = 0; i < json_response.length; i ++) {
                json_response.is_loading = false;
                new_list.push(json_response[i])
                full_list.push(json_response[i]);
            }

            setLoaded(true);
            setFullList(new_list);
            console.log("full_list.length  " + full_list.length);
            onChangeSearch();

        } catch(e) {
            console.error(e);
            
        } 
        
    }

    function onClickAddNew() {

        try {
            Handler.showAddNewPopup();
        } catch(e) {
            console.error(e);
        }
    }

    function getDate(data) {
        
        try {
        
            const d = new Date(data.inserted_on);
            return d.toLocaleDateString();
        } catch(e) {
            console.error(e);
        }

        return "";
    }

    

    function onChangeSearch() {

        try {

            let search_str =  document.getElementById("search").value.trim();
            let new_list = [];

            for(let i = 0; i < full_list.length; i ++) {
                let data = full_list[i];
                if(search_str == "" || data.name.toLocaleLowerCase().indexOf(search_str.toLocaleLowerCase()) != -1) {
                    new_list.push(full_list[i])
                }
                
            }

            setList(new_list);


        } catch(e) {
            console.error(e);
        }
        
    }
    
    async function onClickPause(data) {

        try {

            if(data.is_loading == true) {
                return;
            }

            console.log("onClickPause --------------------------------- " + data.name + ' , active ' + data.active)
            let json = {
                name: name,
                sip_id: data.sip_id,
            };
            
            var new_list = [];
            let search_str =  document.getElementById("search").value.trim();

            for(let i = 0; i < full_list.length; i ++) {
                if(full_list[i].name == data.name) {
                    full_list[i].is_loading = true;
                }
                if(search_str == "" || full_list[i].name.toLocaleLowerCase().indexOf(search_str.toLocaleLowerCase()) != -1) {
                    new_list.push(full_list[i])
                }

            }

            console.dir(new_list);

            setList(new_list);

            let url = config.URL + (data.active == 1 ? "/hold_user" : '/resume_user');
            let response = await fetch(url, {

                method: 'POST',
                body: JSON.stringify(json),
                mode: 'cors',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }

            });

            let json_response = await response.json();
            if(json_response.error) {
                alert("Error creating customer reason: " + json_response.error);
                return;
            }

            getAllUsers();
            
        } catch(e) {
            console.error(e);
        }
    }

    async function downloadExe(data) {
        try {
            window.open(config.URL + "/download_exe?name=" + data.name, '_self');
        } catch(e) {
            console.error(e);
        }
    }

    async function checkDownloadStatus(data) {

        try {

            let url = config.URL + '/is_download_exist?name=' + data.name;
            let response = await fetch(url, {

                method: 'GET',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }

            });

            let json_response = await response.json();

            if(json_response.error) {
                alert("Error Downloading exe reason: " + json_response.error);
                getAllUsers();
                return;
            }

            if(json_response.success == true) {
                downloadExe(data);
                getAllUsers();
                return;
            }

            setTimeout(function() {
                checkDownloadStatus(data);
            }, 2 * 1000);

        } catch(e) {
            console.error(e);
        }

    }

    async function onClickDownload(data) {

        try {

            if(data.is_loading == true) {
                return;
            }

            console.log("onClickDownload --------------------------------- " + data.name)
            
            var new_list = [];
            let search_str =  document.getElementById("search").value.trim();

            for(let i = 0; i < full_list.length; i ++) {
                if(full_list[i].name == data.name) {
                    full_list[i].is_loading = true;
                }

                if(search_str == "" || full_list[i].name.toLocaleLowerCase().indexOf(search_str.toLocaleLowerCase()) != -1) {
                    new_list.push(full_list[i])
                }

            }

            console.dir(new_list);
            setList(new_list);


            let url = config.URL + '/is_download_exist?name=' + data.name;
            let response = await fetch(url, {

                method: 'GET',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }

            });

            let json_response = await response.json();

            if(json_response.success == true) {
                downloadExe(data);
                getAllUsers();
                return;
            }

            url = config.URL + '/download?name=' + data.name;
            response = await fetch(url, {

                method: 'GET',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }

            });

            json_response = await response.json();

            console.dir(json_response);

            if(json_response.error) {
                alert("Error Downloading exe reason: " + json_response.error);
                getAllUsers();
                return;
            }

            setTimeout(function() {
                checkDownloadStatus(data);
            }, 2 * 1000);

        } catch(e) {
            console.error(e);
        }
    }

    async function onClickDelete(data) {

        try {

            if(data.is_loading == true) {
                return;
            }

            console.log("onClickDelete --------------------------------- " + data.name)

            let json = {
                name: name,
                sip_id: data.sip_id,
            };
            
            var new_list = [];
            let search_str =  document.getElementById("search").value.trim();

            for(let i = 0; i < full_list.length; i ++) {
                if(full_list[i].name == data.name) {
                    full_list[i].is_loading = true;
                }

                if(search_str == "" || full_list[i].name.toLocaleLowerCase().indexOf(search_str.toLocaleLowerCase()) != -1) {
                    new_list.push(full_list[i])
                }

            }

            console.dir(new_list);

            setList(new_list);

            let url = config.URL + '/delete_user';
            let response = await fetch(url, {

                method: 'POST',
                body: JSON.stringify(json),
                mode: 'cors',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }

            });

            let json_response = await response.json();
            if(json_response.error) {
                alert("Error creating customer reason: " + json_response.error);
                return;
            }

            getAllUsers();
            
        } catch(e) {
            console.error(e);
        }
    }

    function onClickBack() {
        Handler.closeAccountUsersPopup();
    }

    return (

        <div>

            <div style={{display: 'flex', height: '100px'}}>

                <div>

                    <div className="add_account_div_1" style={{marginTop: "0px", display: 'flex'}}>
                        <div onClick={() => onClickBack()} style={{cursor: 'pointer'}}>
                            <img className="account_image" style={{width: '25px', height: '25px', marginLeft: '0px'}} alt="Image" src="./images/back.png"/>
                        </div>
                        <div style={{marginTop: '3px'}}>
                            <span className="add_account_span_1">{name}</span>
                        </div>
                
                    </div>

                    <div className="search_input_div">
                        <input id="search" type="text" className="search_input_text" placeholder="Search" onChange={onChangeSearch}/>
                    </div>

                </div>

            </div>

            {list.length == 0 && loaded == true ? <div class="no-data-display">No Users to display.</div> : ""}
            
            {list.map((data) => (

                <div className="account_view">

                    <div style={{marginLeft: '20px', marginTop: '15px', width: '300px'}}>
                        <div> 
                            <span  style={{fontWeight: 'bold', fontSize: '17px', color: '#0d8ffc'}}>{data.sip_id}</span>
                        </div>
                        <div>
                            <span>created {getDate(data)}</span>
                        </div>
                    </div>

                    {data.active == 1 ? 
                        <div className="center_div" style={{backgroundColor: '#35c68b',}}>  <div style={{marginTop: '7px'}}> <span className="center_div_span">Active</span> </div> </div> 
                            : 
                        <div className="center_div" style={{backgroundColor: '#000000'}}> <div style={{marginTop: '7px'}}>  <span className="center_div_span">Paused</span> </div> </div> 
                    }

                    <div onClick={() => onClickPause(data)}>
                        {data.active == 1 ?
                            <img className="account_image" style={{width: '25px', height: '25px', marginTop: '27px', marginLeft: '160px'}} alt="Image" src="./images/pause.png"/>
                            : 
                            <img className="account_image" style={{width: '25px', height: '25px', marginTop: '27px', marginLeft: '160px'}} alt="Image" src="./images/play.png"/>
                        }
                    </div>
                  
                    <div onClick={() => onClickDelete(data)}>
                        <img className="account_image" style={{width: '25px', height: '25px', marginTop: '27px', marginLeft: '10px'}} alt="Image" src="./images/delete.png"/>
                    </div>
                    {data.is_loading == true ? <div id='loader_icon' class="popup-data-loader-show-accounts" style={{marginTop: '25px', marginLeft: '10px'}}></div> : ""}
                    
                </div>

            ))}

        </div>
        
    )

}

export default ShowUsers;

