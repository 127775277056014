
var add_new_popup_cb = null;
var close_add_new_popup_cb = null;
var show_account_users_popup_cb = null;
var close_account_users_popup_cb = null;

exports.name = "SoftPhone";

exports.registerAddNewPopup = function(cb) {
    add_new_popup_cb = cb;
}

exports.showAddNewPopup = function() {
    if(add_new_popup_cb) {
        add_new_popup_cb();
    }
}

exports.registerCloseAddNewPopup = function(cb) {
    close_add_new_popup_cb = cb;
}

exports.closeAddNewPopup = function() {
    console.log("close_add_new_popup_cb ");
    if(close_add_new_popup_cb) {
        close_add_new_popup_cb();
    }
}

exports.registerShowAccountUsersPopup = function(cb) {
    show_account_users_popup_cb = cb;
}


exports.showAccountUsersPopup = function() {
    if(show_account_users_popup_cb) {
        show_account_users_popup_cb();
    }
}

exports.registerCloseAccountUsersPopup = function(cb) {
    close_account_users_popup_cb = cb;
}


exports.closeAccountUsersPopup = function() {
    if(close_account_users_popup_cb) {
        close_account_users_popup_cb();
    }
}

