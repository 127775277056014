import React from "react";
import { useState, useEffect  } from "react";
import config from "../config/config"
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Modal from 'react-modal';

import { useNavigate, Link } from "react-router-dom";
import Handler from '../handlers/handler'
import AddAccount from "./AddAccount";
import ShowAccounts from "./ShowAccounts";
import ShowUsers from "./ShowUsers";

function Home() {

    const navigate = useNavigate();

    const [errorMessages, setErrorMessages] = useState({});
    const [show_add_account, setShowAddAccount] = useState(false);
    const [show_all_accounts, setShowAllAccounts] = useState(true);
    const [show_users, setShowUsers] = useState(false);
   
    useEffect(() => {
        //Runs on every render

        try {

            console.log("Came here");

            let status = localStorage.getItem("authenticated");
            console.dir(status);
            if("" + status != "true") {
                console.log("Matched");
                navigate("/login");
            }

            Handler.registerAddNewPopup(showAddNewPopup);
            Handler.registerCloseAddNewPopup(closeAddNewPopup);

            Handler.registerShowAccountUsersPopup(showAccountsUserPopup);
            Handler.registerCloseAccountUsersPopup(closeAccountsUserPopup);

            
            
            // Call the function on initial page load
            updateWindowWidth();
            
            // Add event listener for window resize
            window.addEventListener('resize', updateWindowWidth);

        } catch(e) {
            console.error(e);
        }

    }, []);

    function updateWindowWidth() {
        try {
            console.log("window.innerWidth " + window.innerHeight)
            document.documentElement.style.setProperty('--window-height', window.innerHeight + 'px');
        } catch(e) {
            console.error(e);
        }

    }

    function showAddNewPopup() {
        
        try {

            setShowAddAccount(true);
            setShowAllAccounts(false);
            setShowUsers(false);
            

        } catch(e) {
            console.error(e);
        }

    }

    function closeAddNewPopup() {
        
        try {
            console.log("closePopip");
            setShowAddAccount(false);
            setShowAllAccounts(true);
            setShowUsers(false);
        } catch(e) {
            console.error(e);
        }

    }


    function showAccountsUserPopup() {
        
        try {

            setShowAddAccount(false);
            setShowAllAccounts(false);
            setShowUsers(true);
            

        } catch(e) {
            console.error(e);
        }

    }

    function closeAccountsUserPopup() {
        
        try {
            console.log("closePopip");
            setShowAddAccount(false);
            setShowAllAccounts(true);
            setShowUsers(false);
        } catch(e) {
            console.error(e);
        }

    }

    function getName() {

        try {

            let name = localStorage.getItem("UserName");
            if(name) {
                return name;
            }

        } catch(e) {
            console.error(e);
        }

        return "";
    }

    function onClickLogout() {

        try {

            localStorage.setItem("authenticated", "false");
            localStorage.setItem("LOGIN_USER", "");
            localStorage.setItem("UserName", "");
            navigate("/login");

        } catch(e) {
            console.error(e);
        }
    }

    return (

        <div className="main_div">

            <div className="top_div">

                <div className="app_name_div">
                    <span className="app_name">OnePhone</span>
                </div>

                <div className="right_div">
                    <div className="profile_image">
                        <img src="./images/avatar.png" alt="user-image" className="rounded-circle"/>
                    </div>
                    <div className="profile_image">
                        <div style={{marginTop: '2px', marginLeft: '5px'}}>
                            <span className="profile_name">{getName()}</span>
                        </div>
                    </div>
                </div>

            </div>

            <hr className="hr"></hr>

            <div className="bottom_view" style={{display: 'flex'}}> 

                <div id="left_border" className="left_border">
                    
                    <div onClick={() => closeAddNewPopup()} style={{cursor: 'pointer'}}>
                        <img className="account_image" style={{width: '60px', height: '60px', marginLeft: '13px'}} alt="Image" src="./images/home.png"/>
                    </div>

                    <div className="left_border_logout" onClick={() => onClickLogout()} style={{cursor: 'pointer'}}>
                        <img className="account_image" style={{width: '60px', height: '60px', marginLeft: '13px'}} alt="Image" src="./images/logout.png"/>
                    </div>

                </div>
                <div className="outer ">
                        {show_add_account == true ? <div className="inner_add_account"><AddAccount></AddAccount></div> : ""}
                        {show_all_accounts == true ? <div className="inner"><ShowAccounts></ShowAccounts></div> : ""}
                        {show_users == true ? <div className="inner_show_users"><ShowUsers></ShowUsers></div> : ""}
                </div>

            </div>
            

        </div>
        
    )

}

export default Home;
