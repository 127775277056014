import React from "react";
import { useState, useEffect } from "react";
import config from "../config/config"
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Modal from 'react-modal';

import { useNavigate, Link } from "react-router-dom";
import Handler from '../handlers/handler'
import $ from 'jquery';

function AddAccount() {

    const navigate = useNavigate();

    const [errorMessages, setErrorMessages] = useState({});

    let file = null;

    useEffect(() => {
        //Runs on every render

        try {

            document.getElementById("upload_image").value = "";
            document.getElementById("loader_icon").style.display = "none";
        } catch (e) {
            console.error(e);
        }

    }, []);

   
    async function addAccount(filename) {
        try {

            let name = document.getElementById("appname");

            let json = {
                name: name.value.trim(),
                filename: filename
            };

            let url = config.URL + "/create_account";
            let response = await fetch(url, {

                method: 'POST',
                body: JSON.stringify(json),
                mode: 'cors',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }

            });

            let json_response = await response.json();
            if(json_response.error) {
                alert("Error creating customer reason: " + json_response.error);
                return;
            }

            console.dir(json_response);
            Handler.closeAddNewPopup();

        } catch(e) {
            console.error(e);
            alert("Unable to add new customer");
        } finally  {
            document.getElementById("loader_icon").style.display = "none";
        }
    }

    function uploadFile() {

        try {

            const the_blob_file = new File([file], "blob.png", { lastModified: new Date().getTime(), type: 'image/png' });
            var form_data = new FormData();
            form_data.append("image", the_blob_file);

            $.ajax({
                type: "POST",
                url: config.URL + "/upload_app_image", 
                data: form_data,
                contentType: false,
                processData: false,
                success: function (res) {
    
                    try {
                        console.dir(res);

                        if(res.filename) {
                            addAccount(res.filename)
                            return;   
                        }

                        document.getElementById("loader_icon").style.display = "none";
                        
                    } catch (e) {
                        console.error(e);
                    }
    
                },
                error: function (res) {
                    alert("Unable to upload image");
                    document.getElementById("loader_icon").style.display = "none";
                }
    
            });

        } catch(e) {
            console.error(e);
        }
    }

    function onFileChange(event) {
        try {
            console.log(event.target.value);
            file = event.target.files[0];

            console.dir(file);
            document.getElementById("filename").innerHTML = file.name;

        } catch(e) {
            console.error(e);
        }
    }

    function onClickCancel() {
        try {
            console.log("onClickCancel");
            Handler.closeAddNewPopup();
        } catch(e) {
            console.error(e);
        }
    }

    function onClickSubmit() {
        try {


           
            let name = document.getElementById("appname");
            name.value = name.value.trim();
            if(name.value == "") {
                alert("Please enter the Softphone Name")
                return;
            }

            if(!file) {
                alert("Please upload the app icon 256x256");
                return;
            }

            document.getElementById("loader_icon").style.display = "flex";

            uploadFile();
            
        } catch(e) {
            console.error(e);
        }
    }

    return (

        <div className="add_account_div">

            <div className="add_account_div_1">
                <span className="add_account_span_1">Let's add a new customer</span>
            </div>

            <div className="add_account_div_2">
                <span className="add_account_span_2">What to they want to name their softphone?</span>
            </div>

            <div className="input_div">
                <input id="appname" type="text" className="input_text" placeholder="Softphone Name"/>
            </div>

            <div className="add_account_div_2">
                <span className="add_account_span_2">Please upload company logo icon to use (256x256)</span>
            </div>

            <div className="upload_div">

                <div className="upload_button">
                    <label className="upload_button_sub" for="upload_image">
                        <img className="plus_image" src="./images/plus.png"/>
                        <div className="upload_label">
                            <span  className="upload_label">Upload</span>
                        </div>
                    </label>
                    <input id="upload_image" className="upload_file" type="file" accept=".png" onChange={onFileChange}/>
                    
                </div>
                
                <div className="display_filename">
                    <span id="filename"></span>
                </div>

            </div>

            <div className="submit_button_div">
                <div className="cancel_button" onClick={() => onClickCancel()}>
                    <span className="cancel_span">Cancel</span>
                </div>
                <div className="submit_button" onClick={() => onClickSubmit()}>
                    <span className="submit_span">Submit</span>
                </div>

                <div id='loader_icon' class="popup-data-loader"></div>

            </div>

        </div>
        
    )

}

export default AddAccount;

